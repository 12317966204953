import Div from "@hellodarwin/core/lib/components/common/div";
import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import SeeMoreTags from "@hellodarwin/core/lib/components/common/see-more/see-more-tags";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantProjectTag, GrantTag, NewTag, TagType } from "@hellodarwin/core/lib/features/entities";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  addGrantProjectTags,
  addGrantTags,
  clearAllOptions,
  clearSelectedTags,
  fetchChildTags,
  selectActivityOptions,
  selectActivityTypeOptions,
  selectEligibleExpenseOptions,
  selectSectorOptions,
  selectSelectedTags,
  setSelectedTags,
} from "../../../features/api/slices/grant-tags-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import GrantTagsSearch from "./grant-tag-search";
import message from "antd/es/message";
import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import Select from "antd/es/select";

interface GrantTagsDrawerProps {
  visible: boolean;
  onClose: () => void;
  currentTags: GrantProjectTag[] | GrantTag[];
  entityType: "GrantProject" | "Grant";
  entityId: string;
  locale: string;
}

const GrantTagsDrawer = ({ visible, onClose, currentTags, entityType, entityId, locale }: GrantTagsDrawerProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const sectorOptions = useAppSelector(selectSectorOptions);
  const activityTypeOptions = useAppSelector(selectActivityTypeOptions);
  const activityOptions = useAppSelector(selectActivityOptions);
  const eligibleExpenseOptions = useAppSelector(selectEligibleExpenseOptions);
  const selectedTags = useAppSelector(selectSelectedTags);

  const [selectedSectors, setSelectedSectors] = useState<NewTag[]>([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<NewTag[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<NewTag[]>([]);
  const [selectedEligibleExpenses, setSelectedEligibleExpenses] = useState<NewTag[]>([]);

  // Fetch sector tags initially
  useEffect(() => {
    dispatch(fetchChildTags({ api, parentIds: [], locale, type: TagType.Sector }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch activity type options based on selected sectors
  useEffect(() => {
    const parentIds = selectedSectors?.map((sector) => sector.tag_id);
    dispatch(fetchChildTags({ api, parentIds: parentIds, locale, type: TagType.ActivityType }));
    const newSelectedActivityTypes = selectedActivityTypes.filter((activityType) => {
      return parentIds.includes(activityType.parent_id);
    });
    setSelectedActivityTypes(newSelectedActivityTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectors, locale]);

  // Fetch activity options based on selected activity types
  useEffect(() => {
    const parentIds = selectedActivityTypes?.map((activityType) => activityType.tag_id);
    dispatch(fetchChildTags({ api, parentIds: parentIds, locale, type: TagType.Activity }))
    const newSelectedActivities = selectedActivities.filter((activity) => {
      return parentIds.includes(activity.parent_id);
    });
    setSelectedActivities(newSelectedActivities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityTypes, locale]);

  // Fetch eligible expense options based on selected activities
  useEffect(() => {
    const parentIds = selectedActivities?.map((activity) => activity.tag_id);
    dispatch(fetchChildTags({ api, parentIds: parentIds, locale, type: TagType.EligibleExpense }));
    const newSelectedEligibleExpenses = selectedEligibleExpenses.filter((eligibleExpense) => {
      return parentIds.includes(eligibleExpense.parent_id);
    });
    setSelectedEligibleExpenses(newSelectedEligibleExpenses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivities, locale]);

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    type: TagType
  ) => {
    const options = getOptions(type);
    const newTags = values.map((value) => options[value]);

    setSelected(newTags);

    const updatedSelectedTags = [
      ...selectedTags.filter((tag) => !newTags.some((newTag) => newTag.tag_id === tag.tag_id)),
      ...newTags,
    ];

    dispatch(setSelectedTags(updatedSelectedTags));
  };

  const getOptions = (type: TagType) => {
    switch (type) {
      case TagType.Sector:
        return sectorOptions.entities;
      case TagType.ActivityType:
        return activityTypeOptions.entities;
      case TagType.Activity:
        return activityOptions.entities;
      case TagType.EligibleExpense:
        return eligibleExpenseOptions.entities;
      default:
        return {};
    }
  };

  const handleSubmit = async (tag: NewTag) => {
    const searchResult = []
    do {
      searchResult.push(tag);
      tag = tag.parent as NewTag;
    } while (tag?.parent?.tag_id);
    const tagsToAdd = searchResult.filter(
      (tag) => !currentTags.some((currentTag) => currentTag.tag_id === tag.tag_id)
    );

    if (tagsToAdd.length > 0) {
      if (entityType === "Grant") {
        await dispatch(
          addGrantTags({ api, grantId: entityId, tags: tagsToAdd.map((tag) => tag.tag_id) })
        ).unwrap();
      } else {
        await dispatch(
          addGrantProjectTags({ api, grantProjectId: entityId, newTags: tagsToAdd.map((tag) => tag.tag_id) })
        ).unwrap();
      }
    } else {
      message.success("Tags added successfully");
    }

    dispatch(setSelectedTags([]));
    dispatch(clearAllOptions());
  };

  const handleConfirm = async () => {
    const tagsToAdd = selectedTags.filter(
      (tag) => !currentTags.some((currentTag) => currentTag.tag_id === tag.tag_id)
    );

    if (tagsToAdd.length > 0) {
      if (entityType === "Grant") {
        await dispatch(
          addGrantTags({ api, grantId: entityId, tags: tagsToAdd.map((tag) => tag.tag_id) })
        ).unwrap();
      } else {
        await dispatch(
          addGrantProjectTags({ api, grantProjectId: entityId, newTags: tagsToAdd.map((tag) => tag.tag_id) })
        ).unwrap();
      }
    }
    else {
      message.success("Tags added successfully");
    }

    dispatch(setSelectedTags([]));
  };



  return (
    <Drawer title="Edit Tags" placement="right" onClose={onClose} open={visible} size="large">
      <Div flex="column" justify="space-between" fullHeight>
        <Div flex="column" gap={8}>
          <Div>
            <Typography elementTheme="body1">Global Search</Typography>
            <GrantTagsSearch locale={locale} handleSubmit={handleSubmit} />
          </Div>
          <Div>
            <Typography elementTheme="body1">Search By Type</Typography>
            <Grid sm={2} gutter={16}>
              {[TagType.Sector, TagType.ActivityType, TagType.Activity, TagType.EligibleExpense].map((type, idx) => (
                <Div key={idx}>
                  <Typography elementTheme="body3">{type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}</Typography>
                  <Select
                    mode="multiple"
                    placeholder={`Select ${type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}}(s)`}
                    value={
                      type === TagType.Sector
                        ? selectedSectors.map((tag) => tag.tag_id)
                        : type === TagType.ActivityType
                          ? selectedActivityTypes.map((tag) => tag.tag_id)
                          : type === TagType.Activity
                            ? selectedActivities.map((tag) => tag.tag_id)
                            : selectedEligibleExpenses.map((tag) => tag.tag_id)
                    }
                    onChange={(values) => handleTagChange(values,
                      type === TagType.Sector
                        ? setSelectedSectors
                        : type === TagType.ActivityType
                          ? setSelectedActivityTypes
                          : type === TagType.Activity
                            ? setSelectedActivities
                            : setSelectedEligibleExpenses,
                      type
                    )}
                    options={Object.values(getOptions(type))?.map((option) => ({
                      label: option.content,
                      value: option.tag_id,
                    }))}
                    style={{ width: "100%" }}
                  />
                </Div>
              ))}
            </Grid>
          </Div>
          <Div flex="row" gap={8} justify="center">
            <Button type="primary" onClick={handleConfirm}>
              Add Tags
            </Button>
            <Button onClick={() => { dispatch(clearSelectedTags()); dispatch(clearAllOptions()); }}>
              Cancel
            </Button>
          </Div>
        </Div>
        <Div flex="column" gap={8}>
          <Typography elementTheme="body2">{entityType === "Grant" ? "Grant" : "Grant Project"} Tags</Typography>
          <Div flex="row" gap={8} align="center" wrap="wrap">
            <SeeMoreTags limit={10} size="medium" position={"bottom"} content={currentTags} />
          </Div>
        </Div>
      </Div>
    </Drawer>
  );
};

export default GrantTagsDrawer;
