import {
  ApiError,
  ApiErrorInitialState,
  GrantProjectTag,
  GrantTag,
  NewTag,
  TagType
} from "@hellodarwin/core/lib/features/entities";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const grantTagsAdapter = createEntityAdapter({
  selectId: (tag: GrantTag) => tag.id,
});

const grantProjectTagsAdapter = createEntityAdapter({
  selectId: (tag: GrantProjectTag) => tag.id,
});

interface GrantTagsState {
  status: "idle" | "pending";
  error: ApiError;
  grantTags: EntityState<GrantTag, string>;
  grantProjectTags: Record<string,
    Record<
      string,
      {
        entities: EntityState<GrantProjectTag, string>;
        loading: boolean;
      }
    >
  >;
  selectedTags: NewTag[];
  sectorOptions: { entities: Record<string, NewTag>; loading: boolean };
  activityTypeOptions: { entities: Record<string, NewTag>; loading: boolean };
  activityOptions: { entities: Record<string, NewTag>; loading: boolean };
  eligibleExpenseOptions: { entities: Record<string, NewTag>; loading: boolean };
}

const initialState: GrantTagsState = {
  status: "idle",
  error: ApiErrorInitialState,
  grantTags: grantTagsAdapter.getInitialState(),
  grantProjectTags: {},
  selectedTags: [],
  sectorOptions: { entities: {}, loading: false },
  activityTypeOptions: { entities: {}, loading: false },
  activityOptions: { entities: {}, loading: false },
  eligibleExpenseOptions: { entities: {}, loading: false },
};

export const fetchGrantTags = createAsyncThunk<
  GrantTag[],
  { api: AdminApiClient; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantTags",
  async ({ api, grantId, locale }, { rejectWithValue }) => {
    try {
      return await api.fetchGrantTags(grantId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTagsByProjectId = createAsyncThunk<
  { tags: GrantProjectTag[], grantProjectId: string, locale: string },
  { api: AdminApiClient; grantProjectId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchTagsByProjectId",
  async ({ api, grantProjectId, locale }, { rejectWithValue }) => {
    try {
      const tags = await api.fetchTagsByProjectId(grantProjectId, locale);
      return { tags, grantProjectId, locale };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateGrantProjectTags = createAsyncThunk<
  { tags: GrantProjectTag[], grantProjectId: string, locales: string[] },
  { api: AdminApiClient; grantProjectId: string },
  { rejectValue: ApiError }
>(
  "admin/generateGrantProjectTags",
  async ({ api, grantProjectId }, { rejectWithValue }) => {
    try {
      const tags = await api.generateGrantProjectTags(grantProjectId);
      const locales = tags.map(tag => tag.locale).filter((value, index, self) => self.indexOf(value) === index);
      return { tags, grantProjectId, locales };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchChildTags = createAsyncThunk<
  { tags: NewTag[]; type: TagType },
  { api: AdminApiClient; parentIds: string[]; type: TagType; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchChildTags",
  async ({ api, parentIds, type, locale }, { rejectWithValue }) => {
    try {
      return await api.fetchTagsByParentIds(parentIds, type, locale);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addGrantTags = createAsyncThunk<
  GrantTag[],
  { api: AdminApiClient; grantId: string; tags: string[] },
  { rejectValue: ApiError }
>(
  "admin/addGrantTags",
  async ({ api, grantId, tags }, { rejectWithValue }) => {
    try {
      return await api.addGrantTags(grantId, tags);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addGrantProjectTags = createAsyncThunk<
  { tags: GrantProjectTag[], grantProjectId: string, locales: string[] },
  { api: AdminApiClient; grantProjectId: string; newTags: string[] },
  { rejectValue: ApiError }
>(
  "admin/addGrantProjectTags",
  async ({ api, grantProjectId, newTags }, { rejectWithValue }) => {
    try {
      const tags = await api.addGrantProjectTags(grantProjectId, newTags);
      const locales = tags.map(tag => tag.locale).filter((value, index, self) => self.indexOf(value) === index);
      return { tags, grantProjectId, locales };
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantTagsSlice = createSlice({
  name: "grantTags",
  initialState,
  reducers: {
    setSelectedTags(state, { payload }) {
      state.selectedTags = payload;
    },
    clearSelectedTags(state) {
      state.selectedTags = [];
    },
    clearAllOptions(state) {
      state.activityTypeOptions.entities = {};
      state.activityOptions.entities = {};
      state.eligibleExpenseOptions.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGrantTags.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchGrantTags.fulfilled, (state, action) => {
        state.status = "idle";
        grantTagsAdapter.setAll(state.grantTags, action.payload);
      })
      .addCase(fetchGrantTags.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload!;
      })
      .addCase(fetchTagsByProjectId.pending, (state, { meta }) => {
        const { grantProjectId, locale } = meta.arg;
        if (!state.grantProjectTags[locale]) {
          state.grantProjectTags[locale] = {};
        }
        if (!state.grantProjectTags[locale][grantProjectId]) {
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.getInitialState(),
            loading: true,
          };
        } else {
          state.grantProjectTags[locale][grantProjectId].loading = true;
        }
      })
      .addCase(fetchTagsByProjectId.fulfilled, (state, { payload }) => {
        const { tags, grantProjectId, locale } = payload;
        if (!state.grantProjectTags[locale]) {
          state.grantProjectTags[locale] = {};
        }
        if (!state.grantProjectTags[locale][grantProjectId]) {
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.getInitialState(),
            loading: false,
          };
        }
        state.grantProjectTags[locale][grantProjectId] = {
          entities: grantProjectTagsAdapter.setAll(
            {
              ...state.grantProjectTags[locale][grantProjectId].entities,
            },
            tags
          ),
          loading: false,
        };
        state.status = "idle";
      })
      .addCase(fetchTagsByProjectId.rejected, (state, { payload, meta }) => {
        const { grantProjectId, locale } = meta.arg;
        if (state.grantProjectTags[locale] && state.grantProjectTags[locale][grantProjectId]) {
          state.grantProjectTags[locale][grantProjectId].loading = false;
        }
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      })
      .addCase(fetchChildTags.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case TagType.Sector:
            state.sectorOptions.loading = true;
            break;
          case TagType.ActivityType:
            state.activityTypeOptions.loading = true;
            break;
          case TagType.Activity:
            state.activityOptions.loading = true;
            break;
          case TagType.EligibleExpense:
            state.eligibleExpenseOptions.loading = true;
            break;
        }
      })
      .addCase(fetchChildTags.fulfilled, (state, { payload }) => {
        const { tags, type } = payload;
        const entities = tags?.reduce<Record<string, NewTag>>((acc, tag) => {
          acc[tag.tag_id] = tag;
          return acc;
        }, {});
        switch (type) {
          case TagType.Sector:
            state.sectorOptions.entities = entities ?? {};
            state.sectorOptions.loading = false;
            break;
          case TagType.ActivityType:
            state.activityTypeOptions.entities = entities ?? {};
            state.activityTypeOptions.loading = false;
            break;
          case TagType.Activity:
            state.activityOptions.entities = entities ?? {};
            state.activityOptions.loading = false;
            break;
          case TagType.EligibleExpense:
            state.eligibleExpenseOptions.entities = entities ?? {};
            state.eligibleExpenseOptions.loading = false;
            break;
        }
      })
      .addCase(fetchChildTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      })
      .addCase(addGrantTags.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
      })
      .addCase(addGrantTags.pending, (state) => {
        state.status = "pending";
      })
      .addCase(addGrantTags.fulfilled, (state, { payload }) => {
        grantTagsAdapter.setAll(state.grantTags, payload);
        state.status = "idle";
      })
      .addCase(addGrantProjectTags.pending, (state, { meta }) => {
        const { grantProjectId } = meta.arg;
        // Set loading to true for all locales that contain the grantProjectId
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: true,
            };
          } else {
            state.grantProjectTags[locale][grantProjectId].loading = true;
          }
        });
      })
      .addCase(addGrantProjectTags.fulfilled, (state, { payload }) => {
        const { tags, grantProjectId, locales } = payload;
        locales.forEach((locale) => {
          if (!state.grantProjectTags[locale]) {
            state.grantProjectTags[locale] = {};
          }
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: false,
            };
          }
          const localeSpecificTags = tags.filter((tag) => tag.locale === locale);
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.setAll(
              {
                ...state.grantProjectTags[locale][grantProjectId].entities,
              },
              localeSpecificTags
            ),
            loading: false,
          };
        });
        state.status = "idle";
      })
      .addCase(addGrantProjectTags.rejected, (state, { payload, meta }) => {
        const { grantProjectId } = meta.arg;
        // Clear loading state for all locales that contain the grantProjectId
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId].loading = false;
          }
        });
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      })
      .addCase(generateGrantProjectTags.pending, (state, { meta }) => {
        const { grantProjectId } = meta.arg;
        // Note: We don't have `locales` in `meta.arg`, so let's mark loading for all locales for the grant project
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: true,
            };
          } else {
            state.grantProjectTags[locale][grantProjectId].loading = true;
          }
        });
      })
      .addCase(generateGrantProjectTags.fulfilled, (state, { payload }) => {
        const { tags, grantProjectId, locales } = payload;
        locales.forEach((locale) => {
          if (!state.grantProjectTags[locale]) {
            state.grantProjectTags[locale] = {};
          }
          if (!state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId] = {
              entities: grantProjectTagsAdapter.getInitialState(),
              loading: false,
            };
          }
          const localeSpecificTags = tags.filter((tag) => tag.locale === locale);
          state.grantProjectTags[locale][grantProjectId] = {
            entities: grantProjectTagsAdapter.setAll(
              {
                ...state.grantProjectTags[locale][grantProjectId].entities,
              },
              localeSpecificTags
            ),
            loading: false,
          };
        });
        state.status = "idle";
      })
      .addCase(generateGrantProjectTags.rejected, (state, { payload, meta }) => {
        const { grantProjectId } = meta.arg;
        // Clear loading for all locales for this grant project
        Object.keys(state.grantProjectTags).forEach((locale) => {
          if (state.grantProjectTags[locale][grantProjectId]) {
            state.grantProjectTags[locale][grantProjectId].loading = false;
          }
        });
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      });
  },
});

export const { setSelectedTags, clearSelectedTags, clearAllOptions } = grantTagsSlice.actions;

export const selectSectorOptions = (state: RootState) => state.grantTags.sectorOptions;
export const selectActivityTypeOptions = (state: RootState) => state.grantTags.activityTypeOptions;
export const selectActivityOptions = (state: RootState) => state.grantTags.activityOptions;
export const selectEligibleExpenseOptions = (state: RootState) => state.grantTags.eligibleExpenseOptions;
export const selectSelectedTags = (state: RootState) => state.grantTags.selectedTags;

export const {
  selectAll: selectGrantTags,
  selectById: selectGrantTagById,
} = grantTagsAdapter.getSelectors((state: RootState) => state.grantTags.grantTags);

export const selectGrantProjectTagsEntities = createSelector(
  (state: RootState) => state.grantTags.grantProjectTags,
  (_, locale: string) => locale,
  (_, __, grantProjectId: string) => grantProjectId,
  (grantProjectTags, locale, grantProjectId) => {
    const projectTagsState = grantProjectTags[locale]?.[grantProjectId];
    if (projectTagsState) {
      return Object.values(projectTagsState.entities.entities) as GrantProjectTag[]
    }

    return [];
  }
);

export const selectGrantTagsLoading = (state: RootState) => state.grantTags.status === "pending";
export const selectGrantTagsError = (state: RootState) => state.grantTags.error;

export const grantTagsReducer = grantTagsSlice.reducer;
