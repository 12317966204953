import { IconProps } from "../features/entities/general";

const Save = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4.88889C0 2.18884 2.05203 0 4.58333 0H21.1908C22.6273 0 24.0052 0.608729 25.021 1.69227L28.4135 5.311C29.4293 6.39454 30 7.86414 30 9.3965V27.1111C30 29.8112 27.948 32 25.4167 32H4.58333C2.05203 32 0 29.8112 0 27.1111V4.88889ZM4.58333 2.66667C3.43273 2.66667 2.5 3.66158 2.5 4.88889V27.1111C2.5 28.3385 3.43273 29.3333 4.58333 29.3333H5V20C5 17.7909 6.67893 16 8.75 16H21.25C23.321 16 25 17.7909 25 20V29.3333H25.4167C26.5673 29.3333 27.5 28.3385 27.5 27.1111V9.3965C27.5 8.57138 27.1927 7.78007 26.6457 7.19662L23.2532 3.57788C22.8197 3.11548 22.2637 2.81367 21.6667 2.70834V7.55556C21.6667 9.76469 19.9877 11.5556 17.9167 11.5556H10.4167C8.3456 11.5556 6.66667 9.76469 6.66667 7.55556V2.66667H4.58333ZM22.5 29.3333V20C22.5 19.2636 21.9403 18.6667 21.25 18.6667H8.75C8.05965 18.6667 7.5 19.2636 7.5 20V29.3333H22.5ZM9.16667 2.66667V7.55556C9.16667 8.29193 9.72632 8.88889 10.4167 8.88889H17.9167C18.607 8.88889 19.1667 8.29193 19.1667 7.55556V2.66667H9.16667Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Save;

