import { IconProps } from "../features/entities/general";

const AddPage = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.2 19.3231V15.4461C27.2 14.1241 26.669 12.8563 25.7238 11.9215C24.7786 10.9867 23.4967 10.4615 22.16 10.4615H19.92C19.4744 10.4615 19.0471 10.2865 18.7321 9.97486C18.417 9.66326 18.24 9.24064 18.24 8.79998V6.58459C18.24 5.26259 17.709 3.99473 16.7638 3.05994C15.8186 2.12514 14.5367 1.59998 13.2 1.59998H10.4M13.76 1.59998H6.48C5.55264 1.59998 4.8 2.34434 4.8 3.26151V28.7384C4.8 29.6556 5.55264 30.4 6.48 30.4H25.52C26.4474 30.4 27.2 29.6556 27.2 28.7384V14.8923C27.2 11.3669 25.784 7.98599 23.2635 5.4932C20.743 3.00041 17.3245 1.59998 13.76 1.59998Z"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 12V24M22 18H10"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default AddPage;

